<template>
    <div class="comp">
        <div class="left_tit">
            <div class="gong_name">
                <div class="borderDiv"></div>
                <div class="titDiv">
                    <p class="name_p">联系我们</p>
                    <p class="eng_p">CONTACT US</p>
                </div>
            </div>
            <div class="ints">
                <div class="jieshao">
                    <p>合作电话：029-89666047</p>
                    <p>工作时间：周一到周五9：00~18：00</p>
                </div>
                <div class="boe_red"></div>
            </div>
            <div class="englis">
                <div class="eng_jie">
                    微信公众号：
                </div>
                <div class="cric">
                    <img src="../../assets/imgs/erwei.png" alt="">
                </div>
            </div>
        </div>
        <div class="right_img">
            <img src="../../assets/imgs/liaxi.png" alt="">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.comp {
    width: 100%;
    height: 95%;
    display: flex;
    justify-content: space-between;
    .left_tit {
        width: 25%;
        height: 100%;
        padding: 0 70px 0 200px;
        // background: red;
        .gong_name {
            position: relative;
            width: 266px;
            height: 108px;
            margin-top: 74px;
            .borderDiv {
                width: 208px;
                height: 108px;
                border-radius: 4px;
                border: 10px solid #f0f0f0;
                position: absolute;
                left: 60px;
                top: -30px;
                z-index: -1;
            }
            .titDiv {
                text-align: left;
                .name_p {
                    color: #2e2133;
                    font-size: 36px;
                    font-weight: 600;
                }
                .eng_p {
                    color: #5c6366;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
        .ints {
            width: 512px;
            text-align: left;
            line-height: 26px;
            margin-top: 50px;
            font-size: 16px;
            font-weight: normal;
            color: #454a4d;
            .boe_red {
                width: 100px;
                height: 3px;
                background: #f30b0b;
                margin-top: 15px;
            }
        }
        .englis {
            width: 512px;
            text-align: left;
            line-height: 26px;
            margin-top: 30px;
            font-size: 16px;
            font-weight: normal;
            color: #8a9499;
            .cric {
                width: 186px;
                height: 186px;
                margin-top: 10px;
                // background: red;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .right_img {
        width: 55%;
        height: 60%;
        padding: 100px 0 100px;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>